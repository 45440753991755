<template>
  <footer>
    <div class="c-footer__top">
      <nav class="c-footer__nav">
        <ul class="c-footer__nav-list">
          <li class="c-nav-item" @click="handleDisclaimerModalOpen()">
            {{ $t("general.disclaimer") }}
          </li>
        </ul>
      </nav>
      <div class="c-footer__location">
        <button
          @click="openTelegram"
          class="c-btn c-btn--padding-0 c-btn--rounded"
        >
          <app-icon class="c-footer__location-icon" name="telegram-symbol" />
        </button>
      </div>
    </div>
    <div class="c-footer__info">
      <p class="c-footer__info-text">
        {{ $t("general.disclaimer1") }}
        {{ $t("general.disclaimer3") }}
      </p>
      <p class="c-footer__info-rights">
        <img
          class="c-footer__info-rights-icon"
          src="../../assets/images/Layer21.png"
          alt=""
        />
        <span>{{ $t("general.footer_copy_rights") }}</span>      
      </p>
    </div>
    <transition name="slide-fade-modal">
      <app-modal
        v-if="aboutUsModalVisible"
        class="c-modal--about"
        title="about_us"
        @close="handleAboutUsModalClose"
      >
        <template slot="content">
          <p>
            {{ $t("general.about_us_info") }}
          </p>
        </template>
      </app-modal>
    </transition>
    <transition name="slide-fade-modal">
      <app-modal
        v-if="dislacmerModalVisible"
        class="c-modal--disclaimer"
        title="disclaimer"
        @close="handleDisclaimerModalClose"
      >
        <template slot="content">
          <p>
            {{ $t("general.disclaimer1") }}
          </p>
          <p>
            {{ $t("general.disclaimer2") }}
          </p>
          <p>
            {{ $t("general.disclaimer3") }}
          </p>
          <p>
            {{ $t("general.disclaimer4") }}
          </p>
          <p>
            {{ $t("general.disclaimer5") }}
          </p>
        </template>
      </app-modal>
    </transition>
  </footer>
</template>

<script>
import AppIcon from "../shared/app-icon";
import AppModal from "../shared/app-modal";

export default {
  name: "Footer",
  components: { AppIcon, AppModal },
  data() {
    return {
      aboutUsModalVisible: false,
      dislacmerModalVisible: false,
    };
  },
  methods: {
    handleDisclaimerModalOpen() {
      this.dislacmerModalVisible = true;
    },
    handleAboutUsModalClose() {
      this.aboutUsModalVisible = false;
    },
    handleDisclaimerModalClose() {
      this.dislacmerModalVisible = false;
    },
    openTelegram() {
      window.open("https://t.me/Luckyslotbet");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/footer";
@import "../../assets/styles/transitions";
</style>
