<template>
  <div class="c-modal-wrap">
    <div class="c-modal">
      <div class="c-modal__inner">
        <div class="c-modal__close-btn">
          <button
            v-if="!hideCloseButton"
            class="c-btn c-btn--padding-0 c-btn--bg-transparent"
            @click="closeModal"
          >
            <app-icon class="c-modal__close-btn-icon" name="icon-close" />
          </button>
        </div>
        <h4 class="c-modal__title">
          {{ $t(`general.${title}`) }}
        </h4>
        <div class="c-modal__ctn">
          <slot name="content"></slot>
        </div>
        <slot name="action"></slot>
        <div class="c-modal__shadow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from "../shared/app-icon";

export default {
  name: "AppModal",
  components: { AppIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      modalOpen: false,
    };
  },
  computed: {
    isModalOpen() {
      return this.modalOpen;
    },
  },
  created() {
    document.addEventListener("keydown", this.keyEventHandler);
    document.documentElement.classList.add("modal-open");
  },
  beforeDestroy() {
    document.documentElement.classList.remove("modal-open");
  },
  methods: {
    keyEventHandler(e) {
      if (e.keyCode === 27 || e.key === "Escape") {
        this.closeModal();
      }
    },
    openModal() {
      this.modalOpen = true;
      document.documentElement.classList.add("modal-open");
    },
    closeModal() {
      this.$emit("close", false);
      document.documentElement.classList.remove("modal-open");
      document.removeEventListener("keydown", this.keyEventHandler);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-modal-wrap {
  background-color: #0b1729c4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;

  &.c-modal--wallet {
    .c-modal__shadow {
      display: none;
    }
  }

  &.c-modal--referral {
    .c-modal__shadow {
      display: none;
    }
  }

  &.c-modal--about {
    .c-modal__shadow {
      display: none;
    }
  }

  &.c-modal--notice {
  }

  &.c-modal--disclaimer {
    .c-modal__shadow {
      display: none;
    }
  }

  @include media-min($screen-tablet-portrait) {
    padding-top: 107px;
    align-items: flex-start;
  }

  @include media-max-height(527px) {
    padding-top: 0;
    align-items: center;
  }
}

.c-modal {
  border-radius: 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  background-color: $blue-02;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  margin: 4px;
  overflow: hidden;
  max-height: 70%;

  @include media-min($screen-tablet-portrait) {
    margin: 0;

    max-width: 624px;
    width: 100%;
  }

  @include landscape() {
    max-height: 95%;
  }
}

.c-modal__inner {
  border-radius: 20px;
  border: solid 1px $blue-primary;
  margin: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;
  padding: 10px 5px 10px 10px;

  @include media-min($screen-tablet-portrait) {
    padding: 20px 10px 10px 20px;
  }
}

.c-modal__ctn {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 5px;
  @include custom-scrollbar;

  @include media-min($screen-tablet-portrait) {
    padding-right: 10px;
  }
}

.c-modal__close-btn {
  position: absolute;
  right: 27px;
  top: 27px;

  &:hover {
    .c-modal__close-btn-icon {
      color: $white;
    }
  }
}

.c-modal__close-btn-icon {
  width: 16px;
  height: 16px;
  transition: $transition-medium;
  color: $blue-primary;
}

.c-modal__title {
  font-size: 28px;
  font-weight: bold;
  background-color: $blue-02;
  display: inline-block;
  margin: -20px auto 0 auto;
  padding: 0 15px;

  @include media-min($screen-tablet-portrait) {
    margin: -30px auto 10px auto;
    padding: 0 20px;
  }
}

.c-modal__subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 7px;
}

.c-modal__shadow {
  background: linear-gradient(
    0deg,
    rgba(24, 44, 74, 1) 0%,
    rgba(21, 38, 41, 0) 100%
  );
  position: absolute;
  left: 50%;
  width: calc(100% - 22px);
  bottom: 22px;
  height: 30px;
  transform: translate(-50%, 10px);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

p {
  margin-bottom: 15px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
