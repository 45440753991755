<template>
  <component
    :is="`${name}-icon`"
    fill="currentColor"
    color="currentColor"
    class="c-icon"
  />
</template>

<script>
export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
