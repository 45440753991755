import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import 'swiper/swiper-bundle.min.css';
import VueI18n from 'vue-i18n';
import translations from './translations'
import vClickOutside from 'v-click-outside'
import store from './store'

Vue.use(vClickOutside)

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));

const icons = require.context('./assets/svg-icons', false, /.svg/);
icons.keys().forEach((element) => {
  let test = element.replace(/^\.\//, '').replace(/\.\w+$/, '') + '-icon';
  Vue.component(test, icons(element));
});

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: translations,
})

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app');