import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '../pages/home-page/home-page.vue';
import gamePage from '../pages/game-page/game-page.vue';
import MainWrapper from  '../components/MainWrapper.vue'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: MainWrapper,
      children: [
        {
          path: '/home',
          name: 'home',
          component: HomePage,
        },
        {
          path: '/game/:gameId',
          name: 'game',
          component: gamePage,
        },
      ]
    },
  ],
});

export default router;