<template>
  <div class="c-notice-modal">
    <div class="c-notice-modal__head">
      <h5 class="c-notice-modal__subtitle">
        {{ $t("general.read_carefully") }}
      </h5>
      <p>
        {{ $t("general.read_carefully_desc") }}
      </p>
    </div>
    <h5 class="c-notice-modal__subtitle">
      {{ $t("general.read_and_conf") }}
    </h5>
    <p>
      {{ $t("general.read_and_conf_desc") }}
    </p>
    <div class="c-notice-modal__action">
      <button
        aria-label="Withdraw"
        class="c-btn c-btn--muted"
        @click="closeModal"
      >
        {{ $t("general.decline") }}
      </button>
      <button
        aria-label="deposit"
        class="c-btn c-btn--success"
        @click="confirm"
      >
        {{ $t("general.confirm") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeModal",
  methods: {
    closeModal() {
      return window.history.go(-1);
    },
    confirm() {
      this.$emit("close", false);
      document.documentElement.classList.remove("modal-open");
      document.removeEventListener("keydown", this.keyEventHandler);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-notice-modal {
  flex: 1 1 auto;
  min-height: 0;
}

.c-notice-modal__subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 7px;
}

.c-notice-modal__head {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $white-01;
}

.c-notice-modal__action {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 75px;

  > button {
    margin-right: 20px;
  }
}

p {
  margin-bottom: 15px;
}
</style>
