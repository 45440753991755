<template>
  <div class="c-referral-modal">
    <div class="c-referral-modal__ctn">
      <h5 class="c-referral-modal__subtitle">
        {{ $t("general.my_referral_link") }}
      </h5>
      <div class="c-referral-modal__form-field">
        <div class="c-referral-modal__form-input">
          <input
            ref="referralLink"
            class="c-input"
            type="text"
            :value="referralLink"
            readonly
          />
        </div>
        <button @click="copy" class="c-btn c-btn--primary">
          {{ $t("general.copy") }}
        </button>
      </div>
      <h5 class="c-referral-modal__subtitle">
        {{ $t("general.how_to_gain_ref_bonus") }}
      </h5>
      <p class="c-referral-modal__text">
        {{ $t("general.how_to_gain_ref_bonus_desc") }}
      </p>
    </div>
    <div class="c-referral-modal__footer">
      <div class="c-referral-modal__info">
        <div class="c-referral-modal__info-row">
          <span class="c-referral-modal__info-row-label">{{
            $t("general.my_bonus")
          }}</span>
          <div class="c-referral-modal__info-row-value">
            <span class="c-referral-modal__info-row-value-text">{{
              $store.getters.getReferralBonus
            }}</span>
          </div>
        </div>
        <div class="c-referral-modal__info-row">
          <span class="c-referral-modal__info-row-label">{{
            $t("general.invitations")
          }}</span>
          <div class="c-referral-modal__info-row-value">
            <span class="c-referral-modal__info-row-value-text">{{
              $store.getters.getNumInvited
            }}</span>
          </div>
        </div>
        <div class="c-referral-modal__info-row">
          <span class="c-referral-modal__info-row-label">{{
            $t("general.my_referrer")
          }}</span>
          <div class="c-referral-modal__info-row-value">
            <span class="c-referral-modal__info-row-value-text">{{
              $store.getters.referrer ? $store.getters.referrer : "N/A"
            }}</span>
          </div>
        </div>
        <button
          @click="claimReferral()"
          aria-label="Withdraw"
          class="c-btn c-btn--success"
        >
          {{ $t("general.withdraw") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { currencies } from "../../constants/index";

export default {
  name: "ReferralModal",
  components: {},
  data() {
    return {
      currencies: currencies,
    };
  },
  computed: {
    referralLink() {
      return "https://luckyslot.bet/?ref=" + this.$store.getters.getUsername;
    },
  },
  methods: {
    copy() {
      this.$refs.referralLink.select();
      document.execCommand("copy");
    },
    claimReferral() {
      if (this.$store.state.referralBonus > 0) {
        this.$store.dispatch("claimReferral");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-referral-modal {
  flex: 1 1 auto;
  min-height: 0;
}

.c-referral-modal__subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 7px;
}

.c-referral-modal__text {
  color: $white-05;
  font-family: $roboto-condensed;
  margin-bottom: 15px;
}

.c-referral-modal__form-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.c-referral-modal__form-input {
  flex: 1 1 auto;
  margin-right: 10px;
}

.c-referral-modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin: 12px 0 40px 0;
  border-top: 1px solid $white-01;

  .c-btn {
    margin: 5px auto -45px auto;
  }
}

.c-referral-modal__info {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: solid 1px $green;
  padding: 12px 18px 25px 12px;
  text-align: center;
  min-width: 60%;
}

.c-referral-modal__info-row {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: $white;
  margin-bottom: 6px;
}

.c-referral-modal__info-row-label {
  flex: 1 0 auto;
  width: 90px;
  text-align: right;
  margin-right: 8px;
}

.c-referral-modal__info-row-value {
  flex: 1 1 50%;
  text-align: left;
}

.c-referral-modal__info-row-value-text {
  border-radius: 5px;
  background-color: $blue-03;
  padding: 3px 5px;
  display: inline-block;
  white-space: nowrap;
}
</style>
