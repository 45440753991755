<template>
  <header v-if="!desktopHeader" class="c-header">
    <div class="c-header-wrap">
      <div class="c-header-left">
        <router-link
          :to="{ path: '/', query: $route.query }"
          class="c-header__logo"
        >
          <img
            class="c-header__logo-img"
            src="../../assets/images/luckyslot-logo.png"
            alt=""
          />
        </router-link>
        <nav v-if="$store.getters.getUsername" class="c-header__nav">
          <ul class="c-header__nav-list">
            <li class="c-nav-item" @click="handleReferralModalOpen">
              {{ $t("general.referral") }}
            </li>
            <li class="c-nav-item is-for-token">
              {{ $t("general.token") }}
              <span class="c-nav-item__dropdown-body">
                {{ $t("general.coming_soon") }}
              </span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="c-header-right">
        <div class="c-header-right__item">
          <app-dropdown
            class="c-dropdown--lang"
            :data="languages"
            :callback="changeLanguage"
          >
            <template slot="head">
              <div class="c-dropdown__head-icon">
                <img
                  class="c-dropdown__icon-img"
                  :src="getLangImgUrl(currentLang)"
                  alt=""
                />
              </div>
              <div class="c-dropdown__head-text">
                {{ langCodeUpperCase }}
              </div>
            </template>
          </app-dropdown>
        </div>

        <div class="c-header-right__item">
          <app-dropdown
            class="c-dropdown--currency"
            :data="chains"
            :callback="changeChain"
          >
            <template slot="head">
              <div
                v-if="$store.getters.getUsername"
                class="c-dropdown__head-icon"
              >
                <app-icon class="u-color-white" name="icon-wallet" />
              </div>
              <div
                v-if="$store.getters.getUsername"
                class="c-dropdown__head-text"
              >
                {{ $store.getters.getOnChainBalance }}
              </div>
              <div class="c-dropdown__head-currency-icon-wrap">
                <img
                  class="c-dropdown__head-currency-icon"
                  :src="getChainImgUrl(this.$store.state.chain)"
                  alt=""
                />
              </div>
            </template>
          </app-dropdown>
        </div>
        <div v-if="$store.getters.getUsername" class="c-header-right__item">
          <div class="c-wallet-static" @click="handleWalletModalOpen()">
            <div class="c-wallet-static__icon">
              <app-icon class="u-color-white" name="icon-wallet" />
            </div>
            <div class="c-wallet-static__text">
              {{ $store.getters.getOffChainBalance }}
            </div>
          </div>
        </div>
        <div v-if="$store.getters.getUsername" class="c-header-right__item">
          <app-dropdown
            svg-icon
            class="c-dropdown--profile"
            :data="userSettings"
            :callback="userSettingsCallback"
          >
            <template slot="head">
              <div class="c-dropdown__head-text">
                {{ $store.getters.getUsername }}
              </div>
            </template>
          </app-dropdown>
        </div>

        <div
          v-if="!$store.getters.getUsername"
          v-click-outside="closeLoginDropdown"
          class="c-header-right__item"
          @click="toggleLoginDropdown"
        >
          <div
            class="c-dropdown-basic c-dropdown-basic--primary c-dropdown-basic--header"
          >
            <div class="c-dropdown-basic__label">
              {{ $t("general.login") }}
            </div>
            <transition name="slide-fade-dropdown">
              <span v-if="loginDropdown" class="c-dropdown-basic__body">
                <div
                  v-for="wallet in availableWallets"
                  :key="wallet.name"
                  @click="login(wallet.name)"
                  class="c-dropdown-basic__item"
                >
                  <div class="c-dropdown-basic__item-icon-wrap">
                    <img
                      class="c-dropdown-basic__item-icon"
                      :src="getWalletImgUrl(wallet)"
                      alt="scatter-login"
                    />
                  </div>
                  <div class="c-dropdown-basic__item-text">
                    {{ wallet.displayName }}
                  </div>
                </div>
              </span>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-fade-modal">
      <app-modal
        v-if="walletModalVisible"
        title="wallet"
        class="c-modal--wallet"
        @close="handleWalletModalClose"
      >
        <template slot="content">
          <wallet-modal />
        </template>
      </app-modal>
      <app-modal
        v-if="referralModalVisible"
        title="referral_bonus"
        class="c-modal--referral"
        @close="handleReferralModalClose"
      >
        <template slot="content">
          <referral-modal />
        </template>
      </app-modal>
    </transition>
  </header>
  <header v-else class="c-header c-header--mobile">
    <router-link to="/" class="c-header__logo">
      <img
        class="c-header__logo-img"
        src="../../assets/images/luckyslot-logo.png"
        alt=""
      />
    </router-link>
    <div class="u-ml-auto">
      <div
        v-if="$store.getters.getUsername"
        @click="handleWalletModalOpen()"
        class="c-wallet-static"
      >
        <div class="c-wallet-static__icon">
          <app-icon class="u-color-white" name="icon-wallet" />
        </div>
        <div class="c-wallet-static__text">
          {{ $store.getters.getOffChainBalance }}
        </div>
      </div>
    </div>
    <button
      aria-label="header-menu-toggler"
      class="c-header__hamburger-menu-wrap"
      @click="toggleMobileMenu"
    >
      <app-icon
        class="c-header__hamburger-menu u-color-white"
        name="icon-menu"
      />
    </button>
    <transition name="slide-fade-mobile-menu">
      <div v-if="mobileMenuOpen" class="c-header-wrap">
        <div class="c-header-mobile-user">{{ $store.getters.getUsername }}</div>
        <nav class="c-header__nav">
          <ul class="c-header__nav-list">
            <li
              v-if="$store.getters.getUsername"
              class="c-nav-item"
              @click="handleWalletModalOpen"
            >
              {{ $t("general.wallet") }}
            </li>
            <li
              v-if="$store.getters.getUsername"
              class="c-nav-item"
              @click="handleReferralModalOpen"
            >
              {{ $t("general.referral") }}
            </li>
            <li
              v-if="$store.getters.getUsername"
              class="c-nav-item is-for-token"
            >
              {{ $t("general.token") }}
              <span class="c-nav-item__dropdown-body">
                {{ $t("general.coming_soon") }}
              </span>
            </li>
            <li
              v-if="$store.getters.getUsername"
              @click="logout"
              class="c-nav-item"
            >
              {{ $t("general.logout") }}
            </li>

            <li
              v-if="!$store.getters.getUsername"
              class="c-nav-item"
              @click="toggleLoginDropdown"
              v-click-outside="closeLoginDropdown"
            >
              <div
                v-if="!$store.getters.getUsername"
                class="c-dropdown-basic c-dropdown-basic--primary c-dropdown-basic--header"
              >
                <div class="c-dropdown-basic__label">
                  {{ $t("general.login") }}
                </div>
                <transition name="slide-fade-dropdown">
                  <span v-if="loginDropdown" class="c-dropdown-basic__body">
                    <div
                      v-for="wallet in availableWallets"
                      :key="wallet.name"
                      @click="login(wallet.name)"
                      class="c-dropdown-basic__item"
                    >
                      <div class="c-dropdown-basic__item-icon-wrap">
                        <img
                          class="c-dropdown-basic__item-icon"
                          :src="getWalletImgUrl(wallet)"
                          alt="scatter-login"
                        />
                      </div>
                      <div class="c-dropdown-basic__item-text">
                        {{ wallet.displayName }}
                      </div>
                    </div>
                  </span>
                </transition>
              </div>
            </li>
          </ul>
        </nav>
        <app-dropdown
          class="u-mb-20 u-mt-10 c-dropdown--currency"
          :data="chains"
          :callback="changeChain"
        >
          <template slot="head">
            <div
              v-if="$store.getters.getUsername"
              class="c-dropdown__head-icon"
            >
              <app-icon class="u-color-white" name="icon-wallet" />
            </div>
            <div
              v-if="$store.getters.getUsername"
              class="c-dropdown__head-text"
            >
              {{ $store.getters.getOnChainBalance }}
            </div>
            <div class="c-dropdown__head-currency-icon-wrap">
              <img
                class="c-dropdown__head-currency-icon"
                :src="getChainImgUrl(this.$store.state.chain)"
                alt=""
              />
            </div>
          </template>
        </app-dropdown>
        <app-dropdown
          class="c-dropdown--lang"
          toggler-icon="flag-en.png"
          :data="languages"
          :callback="changeLanguage"
        >
          <template slot="head">
            <div class="c-dropdown__head-icon">
              <img
                class="c-dropdown__icon-img"
                :src="getImgUrl(currentLang)"
                alt=""
              />
            </div>
            <div class="c-dropdown__head-text">
              {{ langCodeUpperCase }}
            </div>
          </template>
        </app-dropdown>
      </div>
    </transition>
    <transition name="slide-fade-modal">
      <app-modal
        v-if="walletModalVisible"
        title="wallet"
        class="c-modal--wallet"
        @close="handleWalletModalClose"
      >
        <template slot="content">
          <wallet-modal />
        </template>
      </app-modal>
      <app-modal
        v-if="referralModalVisible"
        title="referral_bonus"
        class="c-modal--referral"
        @close="handleReferralModalClose"
      >
        <template slot="content">
          <referral-modal />
        </template>
      </app-modal>
    </transition>
  </header>
</template>

<script>
// desktop
import AppDropdown from "../shared/app-dropdown";
import AppIcon from "../shared/app-icon";
import AppModal from "../shared/app-modal";
import WalletModal from "../wallet-modal/wallet-modal";
import ReferralModal from "../referral-modal/referral-modal";
import {
  currencies,
  userSettings,
  languages,
  wallets,
} from "../../constants/index";
//
export default {
  name: "AppHeader",
  components: { AppDropdown, AppIcon, AppModal, WalletModal, ReferralModal },
  data() {
    return {
      desktopHeader: window.innerWidth <= 1300,
      currencies,
      userSettings: userSettings,
      languages: languages,
      walletModalVisible: false,
      referralModalVisible: false,
      currentLang: "en",
      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ["dblclick", "click"],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
      isLoggedIn: false,
      // update to true
      mobileMenuOpen: false,
      loginDropdown: false,
    };
  },
  computed: {
    langCodeUpperCase() {
      return this.currentLang.toUpperCase();
    },
    chains() {
      if (
        location &&
        location.hostname &&
        (location.hostname.includes("localhost") ||
          location.hostname.includes("test.") ||
          location.hostname.includes("newsite."))
      ) {
        return this.currencies;
      }
      return this.currencies.filter((chain) => chain.type != "KYLIN");
    },
    availableWallets() {
      if (this.$route.query.utm_source == "tokenpocket") {
        return [];
      } else {
        return wallets;
      }
    },
  },
  created() {
    addEventListener("resize", () => {
      this.desktopHeader = innerWidth <= 1300;
    });
    if (this.$route.query.utm_source == "tokenpocket") {
      localStorage.setItem("wallet", "tokenpocket");
    }
    this.$store.dispatch("setChain", "EOS");
    if (localStorage.getItem("lang")) {
      this.changeLanguage(localStorage.getItem("lang"));
    }
  },
  methods: {
    handleWalletModalClose() {
      this.walletModalVisible = false;
    },
    userSettingsCallback(type) {
      type == "logout" ? this.logout() : this.handleWalletModalOpen();
    },
    login(wallet) {
      this.$store.dispatch("login", wallet);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    handleWalletModalOpen() {
      this.walletModalVisible = true;
    },
    handleReferralModalOpen() {
      if (this.$store.getters.getUsername) {
        this.$store.dispatch("updateReferralInfo");
        this.referralModalVisible = true;
      }
    },
    handleReferralModalClose() {
      this.referralModalVisible = false;
    },
    changeLanguage(locale) {
      if (this.$root.$i18n.locale === locale) {
        return;
      }
      this.currentLang = locale;
      this.$root.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
    },
    changeChain(chain) {
      if (this.$store.state.chain != chain) {
        this.$store.dispatch("setChain", chain);
      }
    },
    getImgUrl(name) {
      return require("../../assets/images/flag-" + name + ".png");
    },
    onClickOutside() {
      this.walletModalVisible = false;
      this.referralModalVisible = false;
      this.referralModalVisible = false;
    },
    getLangImgUrl(lang) {
      return require("../../assets/images/flag-" + lang + ".png");
    },
    getChainImgUrl(chain) {
      return require("../../assets/images/icon-" +
        chain.toLowerCase() +
        ".png");
    },
    getWalletImgUrl(wallet) {
      return require("../../assets/images/logo-" +
        wallet.name.toLowerCase() +
        ".png");
    },
    handler() {
      // console.log('Clicked outside (Using config), middleware returned true :)')
    },
    // Note: The middleware will be executed if the event was fired outside the element.
    //       It should have only sync functionality and it should return a boolean to
    //       define if the handler should be fire or not
    middleware(event) {
      return event.target.className !== "modal";
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    toggleLoginDropdown() {
      if (this.$route.query.utm_source == "tokenpocket") {
        this.login("tokenpocket");
      } else {
        this.loginDropdown = !this.loginDropdown;
      }
    },
    closeLoginDropdown() {
      this.loginDropdown = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/transitions";
</style>