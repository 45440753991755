<template>
  <div class="c-wallet-modal">
    <div class="c-wallet-modal__head">
      <h5 class="c-wallet-modal__subtitle">
        {{ $t("general.my_wallet") }}
      </h5>
      <span class="c-wallet-modal__btc">{{
        $store.getters.getOffChainBalance
      }}</span>
    </div>
    <div class="c-wallet-modal__ctn">
      <h5 class="c-wallet-modal__subtitle">
        {{ $t("general.amount") }}
      </h5>
      <p class="c-wallet-modal__text">
        {{ $t("general.amount_desc") }}
      </p>
      <div class="c-wallet-modal__form-field">
        <div class="c-wallet-modal__form-input">
          <input
            v-model="amount"
            class="c-input"
            min="0"
            type="number"
            step="0.0001"
          />
        </div>
        <div class="c-dropdown__head-text">EOS</div>
      </div>
    </div>
    <div class="c-wallet-modal__action">
      <button
        @click="withdraw()"
        aria-label="Withdraw"
        class="c-btn c-btn--success"
      >
        {{ $t("general.withdraw") }}
      </button>
      <button
        @click="deposit()"
        aria-label="deposit"
        class="c-btn c-btn--primary"
      >
        {{ $t("general.deposit") }}
      </button>
    </div>
  </div>
</template>

<script>
import { currencies } from "../../constants/index";

export default {
  name: "WalletModal",
  components: {},
  data() {
    return {
      currencies,
      amount: 0,
    };
  },
  methods: {
    withdraw() {
      if (Number(this.amount) > 0) {
        this.$store.dispatch("withdraw", Number(this.amount));
      }
    },
    deposit() {
      if (Number(this.amount) > 0) {
        this.$store.dispatch("deposit", {
          amount: Number(this.amount),
          ref: this.$route.query.ref,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-wallet-modal {
  flex: 1 1 auto;
  min-height: 0;
}

.c-wallet-modal__subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 7px;
}

.c-wallet-modal__btc {
  font-size: 20px;
  font-weight: bold;
  color: $green;
  display: block;
  margin-bottom: 5px;
}

.c-wallet-modal__head {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $white-01;
}

.c-wallet-modal__text {
  color: $white-05;
}

.c-dropdown__head-text {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
}

.c-wallet-modal__form-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}

.c-wallet-modal__form-input {
  flex: 1 1 auto;
}

.c-wallet-modal__action {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 75px;

  > button {
    margin-right: 20px;
  }
}
</style>
