module.exports = {
    'EOS': {
        apiUri: "https://api.main.alohaeos.com:443",
        contract: "luckyslotbet",
        chainId: "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906"
    },
    'KYLIN': {
        apiUri: "https://api.kylin.alohaeos.com:443",
        contract: "slotbankbank",
        chainId: "5fff1dae8dc8e2fc4d5b23b2c7665c97f9e9d8edf2b6485a86ba311c25639191"
    }
}