<template>
  <div>
    <app-header />
    <transition name="fade">
      <router-view />
    </transition>
    <div class="l-container">
      <app-footer />
      <transition name="slide-fade-modal">
        <app-modal
          v-if="noticeModalVisible"
          :hideCloseButton="true"
          title="notice"
          class="c-modal--notice"
          @close="handleNoticeModalClose"
        >
          <template slot="content">
            <notice-modal @close="handleNoticeModalClose" />
          </template>
        </app-modal>
      </transition>
    </div>
  </div>
</template>

<script>
import AppHeader from "./header/app-header";
import AppFooter from "./footer/app-footer";
import NoticeModal from "./notice-modal/notice-modal";
import AppModal from "./shared/app-modal";
export default {
  components: { AppHeader, AppModal, AppFooter, NoticeModal },
  data() {
    return {
      noticeModalVisible: false,
    };
  },
  created() {
    this.noticeModalVisible = true;
  },
  methods: {
    handleNoticeModalClose() {
      this.noticeModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>