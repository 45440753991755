<template>
  <div v-click-outside="onClickOutside" class="c-dropdown">
    <div class="c-dropdown__head">
      <slot name="head"></slot>
      <button
        aria-label="dropdown-toggler"
        class="c-dropdown__head-toggler"
        @click="toggleDropdown"
      >
        <app-icon
          :class="['c-dropdown__head-toggler-icon', { open: dropdownOpen }]"
          name="icon-triangle"
        />
      </button>
    </div>
    <transition name="slide-fade-dropdown">
      <div v-if="dropdownOpen" class="c-dropdown__body">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="c-dropdown__body-row"
          @click="
            callback(item.type);
            dropdownOpen = false;
          "
        >
          <div class="c-dropdown__body-row-icon-wrap">
            <img
              v-if="!svgIcon"
              :src="getIconPath(item.icon)"
              class="c-dropdown__body-row-icon"
              alt=""
            />
            <app-icon
              v-else
              class="c-dropdown__body-row-icon"
              :name="item.icon"
            />
          </div>
          <div class="c-dropdown__body-row-text">
            {{ $t("general." + item.label.toLowerCase()) }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppIcon from "../shared/app-icon";

export default {
  name: "AppDropdown",
  components: { AppIcon },
  props: {
    data: {
      type: Array,
      required: true,
    },
    togglerIcon: {
      type: String,
      require: false,
      default: "",
    },
    svgIcon: {
      type: Boolean,
      required: false,
    },
    callback: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dropdownOpen: false,
      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ["dblclick", "click"],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    getIconPath(iconName) {
      return iconName ? require(`../../assets/images/${iconName}`) : "";
    },
    onClickOutside() {
      this.walletModalVisible = false;
      this.referralModalVisible = false;
      this.referralModalVisible = false;
      this.dropdownOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/transitions";
.c-dropdown {
  position: relative;
  font-weight: bold;
  font-size: 12px;

  &.c-dropdown--lang {
    .c-dropdown__body-row-icon-wrap {
      width: 30px;
      margin-right: 10px;
    }

    .c-dropdown__body-row-icon {
      width: 100%;
    }
  }

  &.c-dropdown--currency {
    .c-dropdown__body-row-icon-wrap {
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
    }

    .c-dropdown__body-row-icon {
      height: 100%;
    }
  }

  &.c-dropdown--currency-modal {
    .c-dropdown__body-row-icon-wrap {
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
    }

    .c-dropdown__body-row-icon {
      height: 100%;
    }

    .c-dropdown__head {
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }

  &.c-dropdown--profile {
    .c-dropdown__head {
      box-shadow: 0 0 15px 0 rgba(5, 180, 199, 0.5);
      background-color: $blue-primary;
    }

    .c-dropdown__body-row-icon-wrap {
      height: 20px;
      width: 18px;
      display: flex;
      justify-content: center;
      margin-right: 8px;
    }
    .c-dropdown__body-row-icon {
      color: $white;
      height: 100%;
    }
  }

  @include media-min($screen-tablet-portrait) {
    font-size: 18px;
  }
}

.c-dropdown__body-row-icon {
  display: block;
}

.c-dropdown__head {
  display: flex;
  align-items: center;
  padding: 0.6rem 0.9rem;
  border-radius: 15px;
  border: solid 1px $white-02;
  background-color: $white-01;
  height: 50px;
}

.c-dropdown__head-currency-icon-wrap {
  width: 15px;
  margin: 0 0.5rem;

  @include media-min($screen-tablet-portrait) {
    width: 20px;
    margin: 0 1rem;
  }
}

.c-dropdown__head-currency-icon {
  width: 100%;
  display: block;
}

.c-dropdown__head-toggler {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: $white-01;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition-medium;
  outline: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
  }

  @include media-min($screen-tablet-portrait) {
    width: 37px;
    height: 37px;
  }
}

.c-dropdown__head-toggler-icon {
  width: 11px;
  min-width: 11px;
  max-width: 11px;
  transform: scale(-1) rotate(-90deg);
  transition: $transition-medium;
  color: $white;
  margin-top: 2px;

  &.open {
    transform: scale(1) rotate(-90deg);
    margin-bottom: 4px;
  }
}

.c-dropdown__head-text {
  margin: 0 6px 0 6px;
  color: $white;

  @include media-min($screen-tablet-portrait) {
    margin: 0 7px 0 14px;
  }
}

.c-dropdown__head-icon {
  width: 20px;
  margin-left: 3px;

  @include media-min($screen-tablet-portrait) {
    width: 30px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-height: 20px;
    height: 20px;

    @include media-min($screen-tablet-portrait) {
      width: 30px;
      max-width: 30px;
      max-height: 30px;

      > svg {
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
}

.c-dropdown__icon-img {
  width: 100%;
  display: block;
}

.c-dropdown__body {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border-radius: 15px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px $blue-01;
  background-color: $blue-02;
  padding: 1.6rem;
  z-index: 5;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid $blue-01;
    position: absolute;
    top: -9px;
    right: 17%;
  }
}

.c-dropdown__body-row {
  display: flex;
  align-items: center;
  transition: $transition-medium;
  cursor: pointer;

  &.active {
    color: $white;
  }

  &:not(:last-child) {
    margin-bottom: 11px;
  }

  &:hover {
    color: $white;
  }
}
</style>
