<template>
  <div id="app" :class="['l-main', { 'l-main--home': isHomeScreen }]">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isHomeScreen: false,
    };
  },
  watch: {
    $route(to) {
      this.isHomeScreen = to.name === "home";
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main";
@import "./assets/styles/transitions";
.l-main--home {
  background-image: radial-gradient(circle at 50% 80%, #0f2749, #0b1729 71%);
}
</style>