<template>
  <div class="c-leaderboard-table">
    <div class="c-leaderboard-table__intro">
      <div class="c-leaderboard-table__intro-left">
        <h3 class="c-leaderboard-table__title">
          {{ $t("general.leaderboard") }}
          <div
            class="c-dropdown-basic c-dropdown-basic--table"
            style="cursor: auto"
          >
            <div class="c-dropdown-basic__label">
              <img
                class="c-dropdown-basic__icon"
                style="cursor: pointer"
                src="../../assets/images/icon-info.png"
                alt="more-details"
              />
            </div>

            <span class="c-dropdown-basic__body">
              <div class="c-dropdown-basic__item">
                <div class="c-dropdown-basic__item-text">
                  <p>{{ $t("general.leaderboard_points") }}</p>
                  <p>{{ $t("general.leaderboard_ends") }}</p>
                </div>
              </div>
            </span>
          </div>
        </h3>
        <span class="c-leaderboard-table__subtitle"
          >{{ $t("general.prize_pool") }}:
          {{ leaderboard.prize.toFixed(4) + " EOS" }}</span
        >
      </div>
      <div class="c-leaderboard-table__intro-right">
        <div class="c-leaderboard-table__intro-time">
          <div class="c-filter-date">
            <button
              @click="showThisWeek = !showThisWeek"
              aria-label="filter-week-preview"
              class="c-btn c-btn--padding-0 c-filter-date__button"
            >
              <app-icon
                class="c-filter-date__arrow-next"
                name="icon-triangle"
              />
            </button>
            <span v-if="showThisWeek" class="c-filter-date__value">{{
              $t("general.this_week")
            }}</span>
            <span v-else class="c-filter-date__value">{{
              $t("general.last_week")
            }}</span>
            <button
              @click="showThisWeek = !showThisWeek"
              aria-label="filter-week-next"
              class="c-btn c-btn--padding-0 c-filter-date__button"
            >
              <app-icon
                class="c-filter-date__arrow-prev"
                name="icon-triangle"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="c-leaderboard-table__ctn">
      <div class="c-leaderboard-table__head">
        <div class="c-leaderboard-table__row">
          <div class="c-leaderboard-table__row-item is-for-number"></div>
          <div class="c-leaderboard-table__row-item is-for-bettor">
            {{ $t("general.bettor") }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ $t("general.points") }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-prize">
            {{ $t("general.prize") }}
          </div>
        </div>
      </div>
      <div class="c-leaderboard-table__body">
        <div
          v-for="(item, index) in leaderboard.users"
          :key="index"
          :class="['c-leaderboard-table__row']"
        >
          <div class="c-leaderboard-table__row-item is-for-number">
            <div
              :class="[
                'c-circle c-circle--gray',
                {
                  'c-circle--is-for-me':
                    item.account == $store.getters.getUsername,
                },
              ]"
            >
              <span class="c-circle__value">{{ (index += 1) }}</span>
            </div>
          </div>
          <div
            class="c-leaderboard-table__row-item is-for-bettor is-primary-bold"
          >
            {{ item.account }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ item.bets }}
          </div>
          <div
            :class="[
              'c-leaderboard-table__row-item is-for-prize',
              { 'is-green': item.prize },
            ]"
          >
            {{ item.prize ? item.prize : "-" }}
          </div>
        </div>
        <div
          v-if="$store.getters.getUsername"
          class="c-leaderboard-table__row is-for-me"
        >
          <div class="c-leaderboard-table__row-item is-for-number">
            <div class="c-circle c-circle--gray c-circle--is-for-me">
              <span class="c-circle__value">{{ myRank ? myRank : "-" }}</span>
            </div>
          </div>
          <div
            class="c-leaderboard-table__row-item is-for-bettor is-primary-bold"
          >
            {{ $store.getters.getUsername }}
          </div>
          <div class="c-leaderboard-table__row-item is-for-points">
            {{ leaderboard.myPoints }}
          </div>
          <div class="c-leaderboard-table__row-item is-green is-for-prize">
            {{ myPrize ? myPrize : "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from "../shared/app-icon";

export default {
  name: "LeaderboardTable",
  components: { AppIcon },
  data() {
    return {
      showThisWeek: true,
    };
  },
  computed: {
    leaderboard() {
      if (!this.$store.getters.getLeaderboard.currentLeaderboard) {
        return { prize: 0, users: [], myPoints: 0 };
      }

      if (this.showThisWeek) {
        return this.$store.getters.getLeaderboard.currentLeaderboard;
      } else {
        return this.$store.getters.getLeaderboard.lastLeaderboard;
      }
    },
    myRank() {
      if (!this.leaderboard) {
        return null;
      }
      for (let i = 0; i < this.leaderboard.users.length; i++) {
        if (
          this.leaderboard.users[i].account == this.$store.getters.getUsername
        ) {
          return i + 1;
        }
      }
      return null;
    },
    myPrize() {
      if (!this.leaderboard) {
        return null;
      }
      for (let i = 0; i < Math.min(3, this.leaderboard.users.length); i++) {
        if (
          this.leaderboard.users[i].account == this.$store.getters.getUsername
        ) {
          return this.leaderboard.users[i].prize;
        }
      }
      return null;
    },
  },
  created() {
    this.$store.dispatch("getLeaderboard");
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/filter-date";
@import "../../assets/styles/components/app-circle";
@import "../../assets/styles/components/leaderboard-table";
</style>
