<template>
  <section>
    <div class="c-banner">
      <img
        :src="
          mobile
            ? getIconPath('banner-jackpot-mobile.png')
            : getIconPath('banner-jackpot.png')
        "
        alt="jackpot-banner"
        class="c-banner__img"
      />
      <span class="c-banner__value">{{ this.$store.getters.getJackpot }}</span>
      <div class="c-banner__button">
        <button
          @click="
            $router.push({ path: '/game/kingarthur', query: $route.query })
          "
          aria-label="play-now"
          class="c-btn c-btn--primary"
        >
          {{ $t("general.play_now") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppBanner",
  data() {
    return {
      mobile: window.innerWidth <= 767,
    };
  },
  created() {
    // todo move in store
    addEventListener("resize", () => {
      this.mobile = innerWidth <= 767;
    });
  },
  methods: {
    getIconPath(iconName) {
      return iconName ? require(`../../assets/images/${iconName}`) : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.c-banner {
  position: relative;
}
.c-banner__img {
  width: 100%;
}
.c-banner__button {
  position: absolute;
  right: 20px;
  bottom: 20px;
  @media screen and (min-width: $screen-tablet-portrait) and (max-width: 900px) {
    right: 6px;
    bottom: 6px;
  }
}
.c-banner__value {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6vw;
  color: $blue-primary;
  text-shadow: 0.5px 0.5px $blue-primary-plus-120;
  @include media-min($screen-tablet-portrait) {
    font-size: 1.3vw;
  }
  @media screen and (min-width: $screen-tablet-portrait) and (max-width: 900px) {
    top: 55%;
  }
}
</style>