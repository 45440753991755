let backendUri = 'https://api.luckyslot.bet/';

if (location.protocol !== 'https:') {
    backendUri = 'http://api.luckyslot.bet/';
}

export default new class BackendCommunication {

    async login(chainId, userId) {

        const body = {
            userId,
            chainId
        };

        return await this.request(backendUri + 'login/', body);
    }

    async getAuthToken(chainId, userId, userSig, randomString, serverSig,) {

        const body = {
            userId,
            chainId,
            userSig,
            randomString,
            serverSig,
        };

        return await this.request(backendUri + 'getauthtoken/', body);
    }

    async getAuthTokenAnchor(chainId, userId, userSig, signerRequest) {

        const body = {
            userId,
            chainId,
            userSig,
            signerRequest
        };

        return await this.request(backendUri + 'getauthtokenanchor/', body);
    }

    async bets(chainId, userId) {

        const body = {
            userId,
            chainId
        };

        return await this.request(backendUri + 'bets/', body);
    }

    async init(chainId, userId, game, authData) {

        const body = {
            userId,
            game,
            chainId,
            authData,
        };

        return await this.request(backendUri + 'init/', body);
    }

    async spin(chainId, userId, game, current, authData, reels = null) {
        const body = {
            userId,
            game,
            chainId,
            current,
            authData,
            reels
        };

        return await this.request(backendUri + 'spin/', body);
    }

    async bonus(chainId, userId, game, choice, authData) {
        const body = {
            userId,
            game,
            chainId,
            choice,
            authData
        };

        return await this.request(backendUri + 'bonus/', body);
    }

    async request(uri, body) {
        const response = await fetch(uri, {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        });

        return await response.json();
    }
}