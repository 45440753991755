import { Serialize, JsonRpc } from 'eosjs';
import config from '../config.js'

export default class Eos {

    constructor(chain) {
        this.setChain(chain);
    }

    setChain(chain) {
        this.chain = chain;
        this.rpc = new JsonRpc(config[chain].apiUri);
    }

    async getJackpot() {
        let jackpotTable = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'jackpot');
        if (jackpotTable.rows.length === 0) {
            return 0;
        } else {
            return +jackpotTable.rows[0].amount.replace(' EOS', '');
        }
    }

    async getOffChainBalance(accountName) {
        let result = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'wallet', accountName, accountName);
        if (result.rows.length) {
            return +result.rows[0].balance.replace(' EOS', '');
        }
        return 0;
    }

    async isWalletCreated(accountName) {
        let result = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'wallet', accountName, accountName);
        if (result.rows.length) {
            return true;
        }
        return false;
    }

    async getOnChainBalance(accountName) {
        let result = await this.rpc.get_currency_balance('eosio.token', accountName, 'EOS');
        if (result.length) {
            return +result[0].replace(' EOS', '');
        }
        return 0
    }

    async getReferralBonus(accountName) {
        let result = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'referral', accountName, accountName);
        if (result.rows.length) {
            return +result.rows[0].referral_balance.replace(' EOS', '');
        }
        return 0;
    }

    async getReferrer(accountName) {
        let result = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'wallet', accountName, accountName);
        if (result.rows.length) {
            return result.rows[0].referrer;
        }
        return null;
    }

    async getNumInvited(accountName) {
        let result = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'wallet', accountName, accountName, 2, 'i64');
        let num = result.rows.length.toString();
        if (result.more) {
            num += '+';
        }
        return num;
    }

    async getLeaderboards(accountName) {
        let result = await this.getTableRows(config[this.chain].contract, config[this.chain].contract, 'leaderinfo');
        let week = result.rows[0].current_week;
        let currentLeaderboard = await this.getLeaderboard(week, accountName);
        let lastLeaderboard = { prize: 0, users: [], myPoints: 0 }
        if (week > 0) {
            lastLeaderboard = await this.getLeaderboard(week - 1, accountName);
        }
        return { currentLeaderboard, lastLeaderboard }
    }

    async getLeaderboard(week, accountName) {
        let weekBuffer = new Serialize.SerialBuffer();
        weekBuffer.pushNumberAsUint64(week);
        let week_name = weekBuffer.getName();

        let result = await this.getTableRows(config[this.chain].contract, week_name, 'leaderprize');
        let prize = 0;
        if (result.rows.length) {
            prize = +result.rows[0].prize.split(' ')[0];
        }

        let prizes = [prize / 10 * 7, prize / 5, prize / 10];

        result = await this.getTableRows(config[this.chain].contract, week_name, 'leaderboard', null, null, 2, 'i64', 10, true);
        let users = result.rows;

        if (users.length < 3) {
            prizes[1] += prizes[2];
            if (users.length < 2) {
                prizes[0] += prizes[1];
            }
        }

        for (let i = 0; i < users.length; i++) {
            if (i < 3) {
                users[i].prize = prizes[i].toFixed(4) + ' EOS';
            }
            users[i].bets = +users[i].bets.split(' ')[0];
        }

        let myPoints = 0;
        if (accountName) {
            result = await this.getTableRows(config[this.chain].contract, week_name, 'leaderboard', accountName, accountName);
            if (result.rows.length) {
                myPoints = +result.rows[0].bets.split(' ')[0];
            }
        }

        return {
            prize,
            users,
            myPoints
        }
    }

    async getTableRows(code, scope, table, lower_bound, upper_bound, index_position, key_type, limit = -1, reverse = false) {
        const rows = await this.rpc.get_table_rows({
            json: true,
            code,
            scope,
            table,
            limit,
            reverse,
            show_payer: false,
            lower_bound,
            upper_bound,
            index_position,
            key_type
        });
        return rows;
    }

}