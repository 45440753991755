<template>
  <div class="l-container l-home">
    <main class="l-home__main">
      <slots-blockchain class="l-section l-home__slots" />
      <all-games class="l-section l-home__games" />
      <app-banner class="l-section l-home__jackpot" />
    </main>
    <aside class="l-home__aside">
      <app-badge class="l-section l-home__jackpot-badge" />
      <leaderboard-table
        class="l-section l-home__leaderboard"
      />
      <recent-bets-table class="l-section l-home__recent-bets" />
    </aside>
  </div>
</template>

<script>
import LeaderboardTable from "../../components/leaderbord/leaderboard-table";
import RecentBetsTable from "../../components/recent-bets/recent-bets-table";
import SlotsBlockchain from "../../components/slots-blockchain/slots-blockchain";
import AppBanner from "../../components/shared/app-banner";
import AppBadge from "../../components/shared/app-badge";
import AllGames from "../../components/all-games/all-games";
import { allGames } from "../../constants/index";
export default {
  name: "HomePage",
  components: {
    SlotsBlockchain,
    AllGames,
    AppBanner,
    AppBadge,
    LeaderboardTable,
    RecentBetsTable,
  },
  data() {
    return {
      allGames: allGames,
    };
  },
};
</script>

<style lang="scss" scoped>
.l-home {
  @include media-min(900px) {
    display: flex;
  }
}

.l-home__main {
  flex: 1 1 auto;
  min-width: 0;

  @include media-min(900px) {
    margin-right: 40px;
  }
}

.l-home__aside {
  flex: 1 1 auto;

  @include media-min(900px) {
    min-width: 420px;
  }
}
</style>