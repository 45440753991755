<template>
  <div class="l-game">
    <main class="l-section l-game__iframe">
      <iframe
        v-if="allGames[gameName]"
        class="l-section l-game__iframe-img"
        :src="allGames[gameName].uri"
      ></iframe>
      <div class="c-iframe__actions">
        <button
          aria-label="filter-week-preview"
          class="c-btn c-btn--padding-0 c-iframe__arrow is-for-top"
          @click="scrollBot"
        >
          <app-icon class="c-iframe__arrow-icon" name="icon-triangle" />
        </button>
        <button
          aria-label="filter-week-preview"
          class="c-btn c-btn--padding-0 c-iframe__arrow is-for-bot"
          @click="scrollTop"
        >
          <app-icon class="c-iframe__arrow-icon" name="icon-triangle" />
        </button>
      </div>
    </main>
    <div class="l-container">
      <other-games class="l-section l-game__swiper" />
      <section class="l-section l-game__recent-bets">
        <recent-bets-table
          show-all-collumns
          wrapper-classes="c-recent-bets--game-page"
          class="l-section l-game__recent-bets"
        />
      </section>
    </div>
  </div>
</template>

<script>
import RecentBetsTable from "../../components/recent-bets/recent-bets-table";
import OtherGames from "../../components/other-games/other-games";
import AppIcon from "../../components/shared/app-icon";
import { allGames } from "../../constants/index";
import Backend from "../../classes/backend.js";
export default {
  name: "GamePage",
  components: {
    RecentBetsTable,
    OtherGames,
    AppIcon,
  },
  data() {
    return {
      allGames,
    };
  },
  computed: {
    gameName() {
      return this.$route.params.gameId.split("-").join("");
    },
    loggedIn() {
      return this.$store.getters.getAuthToken != null;
    },
    gameBalance() {
      return this.$store.state.gameBalance;
    },
  },
  watch: {
    async loggedIn(value) {
      if (this.initOnLogin && value) {
        this.$store.dispatch("updateJackpot");
        let initData = await Backend.init(
          this.$store.getters.getChainId,
          this.$store.getters.getUsername,
          this.allGames[this.$route.params.gameId].name,
          this.$store.getters.getAuthToken
        );
        if (
          initData.error == "Authentication Error" ||
          initData.error == "Auth Token Expired"
        ) {
          this.$store.dispatch("auth");
        } else {
          initData.jackpot = this.$store.state.jackpot;
          this.gameWindow.postMessage(JSON.stringify(initData), "*");
          this.initOnLogin = false;
          if (initData.balance) {
            this.$store.commit("setOffChainBalance", initData.balance);
          }
        }
      }
    },
    gameBalance(value) {
      if (this.gameWindow) {
        this.gameWindow.postMessage(
          JSON.stringify({ action: "balance", balance: value }),
          "*"
        );
      }
    },
  },
  async created() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    scrollBot() {
      window.scroll(0, 0);
    },
    scrollTop() {
      window.scrollBy(0, 300);
    },
    async receiveMessage(message) {
      if (typeof message.data !== "string") {
        return;
      }
      let data = JSON.parse(message.data);
      switch (data.action) {
        case "init": {
          this.gameWindow = message.source;
          if (this.$store.getters.getAuthToken) {
            this.$store.dispatch("updateJackpot");
            let initData = await Backend.init(
              this.$store.getters.getChainId,
              this.$store.getters.getUsername,
              this.allGames[this.$route.params.gameId].name,
              this.$store.getters.getAuthToken
            );
            if (
              initData.error == "Authentication Error" ||
              initData.error == "Auth Token Expired"
            ) {
              this.initOnLogin = true;
              this.$store.dispatch("auth");
            } else {
              initData.jackpot = this.$store.state.jackpot;
              message.source.postMessage(JSON.stringify(initData), "*");
              if (initData.balance) {
                this.$store.commit("setOffChainBalance", initData.balance);
              }
            }
          } else {
            this.initOnLogin = true;
          }
          break;
        }
        case "spin": {
          this.$store.dispatch("updateJackpot");
          const current = data.current;
          const reels = data.reels;
          //let amount = data.current.bet * data.current.lines;
          //amount = +amount.toFixed(4);
          //let balance = +(
          //globalBlockchainManager.lastResults["rpcGetWallet"] - amount
          //).toFixed(4);
          //globalBlockchainManager.setWalletBalance(balance);
          let spinData = await Backend.spin(
            this.$store.getters.getChainId,
            this.$store.getters.getUsername,
            this.allGames[this.$route.params.gameId].name,
            current,
            this.$store.getters.getAuthToken,
            reels
          );
          if (spinData.error) {
            alert(spinData.error);
          } else {
            spinData.jackpot = this.$store.state.jackpot;
            message.source.postMessage(JSON.stringify(spinData), "*");
            let self = this;
            if (spinData.balance) {
              setTimeout(function () {
                self.$store.commit("setOffChainBalance", spinData.balance);
              }, 5000);
            }
          }
          this.$store.dispatch("getBets");
          break;
        }
        case "bonus": {
          this.$store.dispatch("updateJackpot");
          const choice = data.choice;
          let bonusData = await Backend.bonus(
            this.$store.getters.getChainId,
            this.$store.getters.getUsername,
            this.allGames[this.$route.params.gameId].name,
            choice,
            this.$store.getters.getAuthToken
          );
          if (bonusData.error) {
            alert(bonusData.error);
          } else {
            bonusData.jackpot = this.$store.state.jackpot;
            message.source.postMessage(JSON.stringify(bonusData), "*");
            if (bonusData.balance) {
              this.$store.commit("setOffChainBalance", bonusData.balance);
            }
          }
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.l-game__iframe {
  position: relative;
}
.l-game__iframe-img {
  width: 100%;
  height: 90vh;
  border: none;
}
.c-iframe__actions {
  position: fixed;
  top: 348px;
  left: 10px;
  z-index: 20;
  display: none;
  pointer-events: none;
  @include media-min($screen-tablet-portrait) {
    display: block;
    pointer-events: all;
  }
}
.c-iframe__arrow {
  display: block;
  width: 37px;
  height: 37px;
  transform: rotate(-90deg);
  &.is-for-bot {
    transform: rotate(90deg);
    margin-top: 13px;
    > svg {
      margin-left: 2px;
      margin-right: 0;
    }
  }
  > svg {
    margin-bottom: -2px;
    margin-right: 1px;
  }
}
.c-iframe__arrow-icon {
  width: 13px;
  height: 15px;
}
.l-game__swiper {
  margin-bottom: 41px;
  @include media-min($screen-tablet-portrait) {
    padding: 0 40px;
  }
  @include media-min($screen-tablet-landscape-lg) {
    padding: 0 120px;
  }
}
.l-game__recent-bets {
  @include media-min($screen-tablet-portrait) {
    padding: 0 40px;
  }
  @include media-min($screen-tablet-landscape-lg) {
    padding: 0 120px;
  }
}
</style>