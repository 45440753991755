<template>
  <div :class="['c-recent-bets', wrapperClasses]">
    <div class="c-recent-bets__intro">
      <h4 class="c-recent-bets__title">
        {{ $t("general.recent_bets") }}
      </h4>
    </div>
    <div class="c-recent-bets__actions">
      <button
        @click="selectBets(0)"
        aria-label="filter-all"
        class="c-btn c-btn--rounded-md"
        :class="{ 'c-btn--primary': betsCategory == 0 }"
      >
        {{ $t("general.all") }}
      </button>
      <button
        @click="selectBets(1)"
        aria-label="filter-big-wins"
        class="c-btn c-btn--rounded-md"
        :class="{ 'c-btn--primary': betsCategory == 1 }"
      >
        {{ $t("general.big_wins") }}
      </button>
      <button
        @click="selectBets(2)"
        aria-label="filter-my-bets"
        class="c-btn c-btn--rounded-md"
        :class="{ 'c-btn--primary': betsCategory == 2 }"
      >
        {{ $t("general.my_bets") }}
      </button>
    </div>
    <div class="c-recent-bets__ctn">
      <div class="c-recent-bets__head">
        <div class="c-recent-bets__row">
          <div class="c-recent-bets__row-item is-for-bettor">
            {{ $t("general.bettor") }}
          </div>
          <div class="c-recent-bets__row-item is-for-game">
            {{ $t("general.game") }}
          </div>
          <div
            v-if="showAllCollumns"
            class="c-recent-bets__row-item is-for-date"
          >
            {{ $t("general.date") }}
          </div>

          <div
            v-if="showAllCollumns"
            class="c-recent-bets__row-item is-for-bet"
          >
            {{ $t("general.bet") }}
          </div>
          <div class="c-recent-bets__row-item is-for-payout">
            {{ $t("general.payout") }}
            (EOS)
          </div>
          <div class="c-recent-bets__row-item is-for-details">
            {{ showAllCollumns ? $t("general.verify") : "" }}
          </div>
        </div>
      </div>
      <div class="c-recent-bets__body">
        <div
          v-for="(item, index) in data"
          :key="index"
          :class="['c-recent-bets__row']"
        >
          <div class="c-recent-bets__row-item is-for-bettor is-primary-bold">
            {{ item.user }}
          </div>
          <div class="c-recent-bets__row-item is-for-game">
            <img
              class="c-recent-bets__row-item-game-icon"
              :src="getIconPath(item.game)"
              alt=""
            />
            <span>
              {{ getGameTitle(item.game) }}
            </span>
          </div>

          <div
            v-if="showAllCollumns"
            class="c-recent-bets__row-item is-for-date"
          >
            {{ item.time | dateFormat }}
            <br />
            {{ item.time | timeFormat }}
          </div>

          <div
            v-if="showAllCollumns"
            class="c-recent-bets__row-item is-for-bet"
          >
            {{ item.betAmount.toFixed(4) }}
          </div>

          <div
            class="c-recent-bets__row-item is-for-payout"
            style="display: inline-block;"
            :class="{ 'is-green': item.payout > 0 }"
          >
            <span v-if="item.isFreeSpin" style="color: #f9fa01">
              Freespin
              <br />
            </span>
            <span v-if="item.isBonus" style="color: #f9fa01">
              Bonus
              <br />
            </span>
            {{ item.payout.toFixed(4) }}
          </div>
          <div class="c-recent-bets__row-item is-for-details">
            <button
              @click="openVerify(item.transactionId)"
              class="c-btn c-btn--padding-0 c-btn--bg-transparent"
            >
              <img
                class="c-recent-bets__row-item-icon"
                src="../../assets/images/icon-info.png"
                alt="more-details"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allGames } from "../../constants/index";

export default {
  name: "RecentBetstable",
  props: {
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
    showAllCollumns: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      allGames,
      betsCategory: 0,
    };
  },
  computed: {
    data() {
      let bets = this.$store.getters.getBets;
      if (!bets.all) {
        return null;
      }
      switch (this.betsCategory) {
        case 0:
          return bets.all;
        case 1:
          return bets.big;
        case 2:
          return bets.mine;
        default:
          return null;
      }
    },
  },
  methods: {
    getIconPath(gameName) {
      return gameName
        ? require(`../../assets/images/${this.allGames[gameName].thumb}`)
        : "";
    },
    getGameTitle(gameName) {
      return gameName ? this.allGames[gameName].title : "";
    },
    selectBets(category) {
      this.betsCategory = category;
    },
    openVerify(transactionId) {
      switch (this.$store.getters.getChainId) {
        case "5fff1dae8dc8e2fc4d5b23b2c7665c97f9e9d8edf2b6485a86ba311c25639191":
          window.open("https://kylin.bloks.io/transaction/" + transactionId);
          break;
        case "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906":
        default:
          window.open("https://bloks.io/transaction/" + transactionId);
          break;
      }
    },
  },
  filters: {
    dateFormat(dateString) {
      let date = new Date(dateString);
      return date.toLocaleString("en-GB", {
        month: "short",
        day: "2-digit",
      });
    },
    timeFormat(dateString) {
      let date = new Date(dateString);
      return date.toLocaleString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/recent-bets-table";
</style>
