export const languages = [
  {
    icon: 'flag-en.png',
    label: 'EN',
    type: 'en',
  },
  /*{
    icon: 'flag-cn.png',
    label: 'CN',
    type: 'cn',
  }*/
];

export const currencies = [
  {
    icon: 'icon-eos.png',
    label: 'EOS',
    type: 'EOS'
  },
  {
    icon: 'icon-kylin.png',
    label: 'KYLIN',
    type: 'KYLIN'
  },
];

export const wallets = [
  /*{
    name: 'scatter',
    displayName: 'Scatter'
  },*/
  {
    name: 'anchor',
    displayName: 'Anchor'
  },
];

export const userSettings = [
  {
    icon: 'icon-wallet',
    label: 'Wallet',
    type: 'wallet',
  },
  {
    icon: 'logout',
    label: 'Logout',
    type: 'logout',
  },
];

export const slotGames = [
  {
    title: 'fairness',
    description: 'fairness_subtitle',
    image: 'fairness.png',
  },
  {
    title: 'Jackpot',
    description:
      'jackpot_subtitle',
    image: 'jackpot-chest.png',
  },
  {
    title: 'more_games',
    description:
      'games_subtitle',
    image: 'moregames.png',
  },
];

export const allGames = {
  kingarthur: {
    title: 'King Arthur',
    name: 'kingarthur',
    uri: 'https://luckyslot.bet/gamestest/king-arthur',
    description:
      'kingarthur_desc',
    image: 'king-arthur.png',
    thumb: 'king-arthur-thumb.png',
    isNew: false
  },
  wildwest: {
    title: 'Wild West',
    name: 'wildwest',
    uri: 'https://luckyslot.bet/gamestest/wild-west',
    description:
      'wildwest_desc',
    image: 'wildwest.png',
    thumb: 'wildwest-thumb.png',
    isNew: false
  },
  nightoffear: {
    title: 'Night of Fear',
    name: 'nightoffear',
    uri: 'https://luckyslot.bet/gamestest/night-of-fear',
    description:
      'nightoffear_desc',
    image: 'night-of-fear.png',
    thumb: 'night-of-fear-thumb.png',
    isNew: false
  },
  cartel: {
    title: 'Cartel',
    name: 'cartel',
    uri: 'https://luckyslot.bet/gamestest/cartel',
    description:
      'cartel_desc',
    image: 'cartel.png',
    thumb: 'cartel-thumb.png',
    isNew: false
  },
  tigerland: {
    title: 'Tiger Land',
    name: 'tigerland',
    uri: 'https://luckyslot.bet/gamestest/tigerland',
    description:
      'tigerland_desc',
    image: 'tigerland.png',
    thumb: 'tigerland-thumb.png',
    isNew: false
  },
  cleopatra: {
    title: 'Cleopatra',
    name: 'cleopatra',
    uri: 'https://luckyslot.bet/gamestest/cleopatra',
    description:
      'cleopatra_desc',
    image: 'cleopatra.png',
    thumb: 'cleopatra-thumb.png',
    isNew: false
  },
  spymaster: {
    title: 'Spymaster',
    name: 'spymaster',
    uri: 'https://luckyslot.bet/gamestest/spymaster',
    description:
      'spymaster_desc',
    image: 'spymaster.png',
    thumb: 'spymaster-thumb.png',
    isNew: false
  },
  samurai: {
    title: 'Samurai Spirit',
    name: 'samurai',
    uri: 'https://luckyslot.bet/gamestest/samurai',
    description:
      'samurai_desc',
    image: 'samurai.png',
    thumb: 'samurai-thumb.png',
    isNew: false
  },
  surfin: {
    title: 'Surfin\' California',
    name: 'surfin',
    uri: 'https://luckyslot.bet/gamestest/surfin',
    description:
      'surfin_desc',
    image: 'surfin.png',
    thumb: 'surfin-thumb.png',
    isNew: false
  },
  pirates: {
    title: 'Treasure Island Quest',
    name: 'pirates',
    uri: 'https://luckyslot.bet/gamestest/treasure-island',
    description:
      'pirates_desc',
    image: 'pirates.png',
    thumb: 'pirates-thumb.png',
    isNew: false
  },
  thebigfive: {
    title: 'The Big Five',
    name: 'thebigfive',
    uri: 'https://luckyslot.bet/gamestest/the-big-5',
    description:
      'thebigfive_desc',
    image: 'thebigfive.png',
    thumb: 'thebigfive-thumb.png',
    isNew: true
  },
  aladdin: {
    title: 'Aladdin',
    name: 'aladdin',
    uri: 'https://luckyslot.bet/gamestest/aladdin',
    description:
      'aladdin_desc',
    image: 'aladdin.png',
    thumb: 'aladdin-thumb.png',
    isNew: true
  },
};