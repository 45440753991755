<template>
  <div class="c-jackpot-badge">
    <h2 class="c-jackpot-badge__title">
      {{ $t("general.jackpot") }}
    </h2>
    <span class="c-jackpot-badge__value">{{
      this.$store.getters.getJackpot
    }}</span>
  </div>
</template>

<script>
export default { name: "AppBadge" };
</script>

<style lang="scss" scoped>
.c-jackpot-badge {
  border-radius: 30px;
  background-color: $blue-02;
  position: relative;
  height: 120px;
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 14px);
    height: calc(100% - 20px);
    border-radius: 20px;
    border: solid 1px $blue-primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-jackpot-badge__title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: $blue-02;
  width: 110px;
  margin: 0 auto;
}

.c-jackpot-badge__value {
  margin: 25px 0 auto 0;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $success;
}
</style>
