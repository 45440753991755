import Vue from 'vue'
import Vuex from 'vuex'

import Eos from '../classes/eos.js';
import Wallet from '../classes/wallet.js';

import Backend from '../classes/backend.js';

const defaultChain = 'EOS';
let eos = new Eos(defaultChain);
let wallet = new Wallet(defaultChain);

Vue.use(Vuex)

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

let getBetsTimeout;

export default new Vuex.Store({
  state: {
    chain: defaultChain,
    jackpot: 0,
    onChainBalance: 0,
    offChainBalance: 0,
    gameBalance: 0,
    referralBonus: 0,
    referrer: null,
    numInvited: 0,
    wallet,
    authToken: null,
    bets: {
      all: [],
      big: [],
      mine: []
    },
    leaderboard: {}
  },
  getters: {
    getUsername: state => {
      return state.wallet.getUsername();
    },
    getJackpot: state => {
      return state.jackpot.toFixed(4) + ' EOS';
    },
    getOnChainBalance: state => {
      if (state.wallet.user) {
        return state.onChainBalance.toFixed(4) + ' EOS';
      }
      return '0.0000 EOS';
    },
    getOffChainBalance: state => {
      if (state.wallet.user) {
        return state.offChainBalance.toFixed(4) + ' EOS';
      }
      return '0.0000 EOS';
    },
    getReferralBonus: state => {
      if (state.wallet.user) {
        return state.referralBonus.toFixed(4) + ' EOS';
      }
      return '0.0000 EOS';
    },
    getReferrer: state => {
      if (state.wallet.user) {
        return state.referrer;
      }
      return null;
    },
    getNumInvited: state => {
      if (state.wallet.user) {
        return state.numInvited;
      }
      return 0;
    },
    getAuthToken: state => {
      if (state.wallet.user)
        return state.authToken;
      return null;
    },
    getChainId: state => {
      return state.wallet.chainId;
    },
    getBets: state => {
      return state.bets;
    },
    getLeaderboard: state => {
      return state.leaderboard;
    }
  },
  mutations: {
    setChain(state, chain) {
      state.chain = chain;
      eos.setChain(chain)
      state.wallet.setChain(chain);
    },
    setJackpot(state, amount) {
      state.jackpot = amount;
    },
    setOnChainBalance(state, amount) {
      state.onChainBalance = amount;
    },
    setOffChainBalance(state, amount) {
      state.offChainBalance = amount;
    },
    setGameBalance(state, amount) {
      state.gameBalance = amount;
    },
    setAuthToken(state, authToken) {
      state.authToken = authToken;
    },
    setReferralBonus(state, referralBonus) {
      state.referralBonus = referralBonus;
    },
    setReferrer(state, referrer) {
      state.referrer = referrer;
    },
    setNumInvited(state, numInvited) {
      state.numInvited = numInvited;
    },
    setBets(state, bets) {
      state.bets = bets;
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard;
    }
  },
  actions: {
    async setChain({ commit, dispatch }, chain) {
      commit('setChain', chain);
      await dispatch('updateJackpot');
      await dispatch('getBets');
      await dispatch('getLeaderboard');
      if (localStorage.getItem('wallet')) {
        await dispatch('login', localStorage.getItem('wallet'));
      }
    },
    async updateJackpot({ commit }) {
      let jackpot = await eos.getJackpot();
      commit('setJackpot', jackpot);
    },
    async updateOnChainBalance({ commit }) {
      let balance = 0;
      if (wallet.user) {
        balance = await eos.getOnChainBalance(wallet.getUsername());
      }
      commit('setOnChainBalance', balance);
    },
    async updateOffChainBalance({ commit }) {
      let balance = 0;
      if (wallet.user) {
        balance = await eos.getOffChainBalance(wallet.getUsername());
      }
      commit('setOffChainBalance', balance);
      commit('setGameBalance', balance);
    },
    async updateReferralInfo({ commit }) {
      if (wallet.user) {
        commit('setReferralBonus', await eos.getReferralBonus(wallet.getUsername()));
        commit('setReferrer', await eos.getReferrer(wallet.getUsername()));
        commit('setNumInvited', await eos.getNumInvited(wallet.getUsername()));
      }
    },
    async claimReferral({ getters, dispatch }) {
      if (wallet.user) {
        let t = await wallet.claimReferral();
        if (t) {
          let referralBonus = getters.getReferralBonus;
          await dispatch('updateReferralInfo');
          await dispatch('updateOnChainBalance');

          while (referralBonus == getters.getReferralBonus) {
            await sleep(1000);
            await dispatch('updateReferralInfo');
            await dispatch('updateOnChainBalance');
          }
        }
      }
    },
    async withdraw({ getters, dispatch }, amount) {
      if (wallet.user) {
        let t = await wallet.withdraw(amount.toFixed(4) + ' EOS');
        if (t) {
          let offChainBalance = getters.getOffChainBalance;
          await dispatch('updateOffChainBalance');
          await dispatch('updateOnChainBalance');

          while (offChainBalance == getters.getOffChainBalance) {
            await sleep(1000);
            await dispatch('updateOffChainBalance');
            await dispatch('updateOnChainBalance');
          }
        }
      }
    },
    async deposit({ getters, dispatch }, params) {
      if (wallet.user) {
        let referrer;
        let ref = params.ref;

        if (
          ref &&
          !(await eos.isWalletCreated(wallet.getUsername())) &&
          ref != wallet.getUsername()
        ) {
          referrer = ref;
        }

        let t = await wallet.deposit(params.amount.toFixed(4) + ' EOS', referrer);
        if (t) {
          let offChainBalance = getters.getOffChainBalance;
          await dispatch('updateOffChainBalance');
          await dispatch('updateOnChainBalance');

          while (offChainBalance == getters.getOffChainBalance) {
            await sleep(1000);
            await dispatch('updateOffChainBalance');
            await dispatch('updateOnChainBalance');
          }
        }
      }
    },
    async login({ dispatch }, walletName) {
      await wallet.login(walletName);

      await dispatch('updateOnChainBalance');
      await dispatch('updateOffChainBalance');
      await dispatch('getBets');
      await dispatch('getLeaderboard');

      await dispatch('auth');
    },
    async logout({ dispatch, commit }) {
      await wallet.logout();
      await dispatch('getBets');
      commit('setOnChainBalance', 0);
      commit('setOffChainBalance', 0);
      commit('setGameBalance', 0);
      commit('setAuthToken', null);
      commit('setReferralBonus', 0);
      commit('setReferrer', null);
      commit('setNumInvited', 0);
    },
    async auth({ commit }) {
      if (wallet.user) {
        let authToken = await wallet.getAuthToken();
        if (authToken) {
          commit('setAuthToken', authToken);
        }
      }
    },
    async getBets({ commit, dispatch, getters }) {
      if (getBetsTimeout) {
        clearTimeout(getBetsTimeout);
      }

      let bets = await Backend.bets(getters.getChainId, getters.getUsername);
      if (bets) {
        commit('setBets', bets);
      }

      getBetsTimeout = setTimeout(function () {
        dispatch('getBets');
      }, 10 * 1000);
    },
    async getLeaderboard({ commit, getters }) {
      let leaderboard = await eos.getLeaderboards(getters.getUsername);
      commit('setLeaderboard', leaderboard);
    }
  }
})
