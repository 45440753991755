<template>
  <section>
    <h1>{{ $t("general.slots_on_blockchain") }}</h1>
    <div class="l-home__slots-wrap">
      <div class="l-home__slots-swiper">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="(slotGame, index) in slotGames" :key="index">
            <app-card
              :img-path="slotGame.image"
              :title="$t('general.' + slotGame.title.toLowerCase())"
              :description="$t('general.' + slotGame.description)"
              :isNewGame="slotGame.isNew"
              wrapper-classes="c-card--slot"
            />
          </swiper-slide>
        </swiper>
        <div class="l-home__slots-swiper-shadow"></div>
        <button
          slot="button-next"
          aria-label="slide-next"
          class="swiper-button-next c-swiper-btn c-swiper-btn--next"
        >
          <app-icon name="icon-triangle" />
        </button>
      </div>
      <div class="l-home__slots-hero">
        <div class="l-home__slots-hero-wrap">
          <img
            class="l-home__slots-hero"
            src="../../assets/images/img-hero.png"
            alt="Home hero image"
          />
        </div>
        <button
          @click="
            $router.push({ path: '/game/kingarthur', query: $route.query })
          "
          aria-label="play-now"
          class="c-btn c-btn--primary"
        >
          {{ $t("general.play_now") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import AppCard from "../shared/app-card";
import AppIcon from "../shared/app-icon";
import { slotGames } from "../../constants/index";

export default {
  name: "SlotsBlockchain",
  components: { AppCard, AppIcon },
  data() {
    return {
      slotGames: slotGames,
      swiperOption: {
        spaceBetween: 15,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
          240: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.c-swiper-btn {
  background-color: transparent;
  border: none;
  outline: transparent;
  width: 26px !important;
  height: 26px !important;
  background-color: $blue-primary;
  border-radius: 50%;
  flex-shrink: 0;

  &::after {
    display: none;
  }

  &.c-swiper-btn--prev {
    transform: rotate(-90deg);
  }

  &.c-swiper-btn--next {
    transform: rotate(90deg);
  }

  > svg {
    width: 13px;
    min-width: 13px;
    max-width: 13px;
    height: 11px;
    color: $white;
    transform: rotate(-90deg);
    margin-bottom: 3px;
  }

  @include media-min($screen-tablet-landscape) {
    opacity: 0;
    display: none;
    pointer-events: none;
  }
}

.l-home__slots-wrap {
  @include media-min($screen-tablet-portrait) {
    display: flex;
    flex-wrap: wrap;
  }
}

.l-home__slots-swiper {
  position: relative;
  overflow: hidden;
  margin-right: -10px;

  @include media-min($screen-tablet-landscape) {
    margin-right: 10px;
    pointer-events: none;
    flex: 1 1 0;
    min-width: 450px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 1 0;
    min-width: 450px;
  }
}

.l-home__slots-swiper-shadow {
  background-image: linear-gradient(to left, #0b1729, rgba(11, 23, 41, 0));
  position: absolute;
  height: 100%;
  width: 60px;
  top: 0;
  right: 0;
  z-index: 2;

  @include media-min($screen-tablet-landscape) {
    opacity: 0;
    display: none;
  }
}

.swiper-container {
  min-width: 335px;
}

.l-home__slots-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 20px auto 0 auto;
  display: none;

  @include media-min($screen-tablet-portrait) {
    max-width: 300px;
    flex: 1 1 0;
    min-width: 150px;
    margin: 0 auto 0 auto;
    display: flex;
  }

  @include media-min(1300px) {
    margin-top: -40px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 150px;
  }
}

.l-home__slots-hero-wrap {
  margin-bottom: 10px;
}
</style>
