<template>
  <article :class="['c-card', wrapperClasses]">
    <h2 class="c-card__title">
      {{ title }}
    </h2>
    <p class="c-card__desc">{{ description }}</p>
    <div class="c-card__img-wrap">
      <img class="c-card__img" :src="getIconPath(imgPath)" :alt="title" />
    </div>
    <img class="c-card__new-game-img" v-if="isNewGame" :src="getIconPath('new-corner-label.png')" />
    <div class="c-card__btn-wrap">
      <button class="c-btn c-btn--padding-0 c-card__btn">
        <app-icon class="c-card__btn-icon" name="icon-triangle" />
      </button>
    </div>
  </article>
</template>

<script>
import AppIcon from "../shared/app-icon";
export default {
  name: "AppCard",
  components: { AppIcon },
  props: {
    title: {
      type: String,
      require: false,
      default: "",
    },
    description: {
      type: String,
      require: false,
      default: "",
    },
    imgPath: {
      type: String,
      require: false,
      default: "",
    },
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
    isNewGame: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      playBtnVisible: false,
    };
  },
  methods: {
    getIconPath(iconName) {
      return iconName ? require(`../../assets/images/${iconName}`) : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.c-card {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background-image: linear-gradient(to bottom, #0b1729, #182c4a);
  padding: 10px;
  pointer-events: all;
  cursor: pointer;

  &.c-card--slot {
    .c-card__title {
      padding-left: 20px;
      font-size: 16px;
      position: relative;

      @include media-max(1400px) {
        font-size: 14px;
      }

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 4px;
        box-shadow: 0 0 15px 0 rgba(245, 57, 131, 0.5);
        background-color: $pink;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    .c-card__desc {
      height: 55px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include media-min($screen-tablet-portrait) {
        height: 65px;
      }
    }
  }

  &.c-card--game {
    flex-direction: column-reverse;
    position: relative;
    padding: 0;
    background: none;

    &:hover {
      .c-card__img-wrap {
        &::after {
          opacity: 1;
        }
      }

      .c-card__desc {
        opacity: 1;
      }

      .c-card__btn-wrap {
        opacity: 1;
      }
    }

    &:active {
      .c-card__btn-wrap {
        transform: translateX(-50%) scale(1.1);
      }
    }

    .c-card__title {
      margin-bottom: 0;
      margin-top: 13px;
      text-transform: capitalize;

      @include media-min($screen-tablet-portrait) {
        margin-top: 5px;
        font-size: 14px;
      }
    }

    .c-card__img-wrap {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        transition: $transition-medium;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-image: linear-gradient(
          to bottom,
          rgba(5, 180, 199, 0),
          #05b4c7
        );

        @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {
          border-radius: 10px;
        } 
      }
    }

    .c-card__desc {
      opacity: 0;
      position: absolute;
      transition: $transition-medium;
      z-index: 5;
      padding: 15px;
      font-weight: bold;
      white-space: pre-wrap;
      color: $white;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      overflow: auto;
      font-size: 18px;
      height: 45%;
      @include custom-scrollbar();

      @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {
        padding: 10px;
        font-size: 10px;
      }
    }
  }
}

.c-card__new-game-img {
  position: absolute;
  left: -1.6%;
  top: -1.8%;
  width: 40%;
}

.c-card__title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $white;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.c-card__desc {
  opacity: 0.5;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 30px;
  font-family: $roboto-condensed;

  @include media-min($screen-tablet-portrait) {
    font-size: 14px;
  }
}

.c-card__img-wrap {
  display: flex;
  justify-content: center;
  width: 85px;
  height: 85px;
  margin: 0 auto;
}

.c-card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-card__btn-wrap {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  transition: $transition-medium;
  opacity: 0;
  z-index: 5;

  svg {
    transform: rotate(-90deg);
    margin-bottom: 6px;
    margin-left: 1px;
  }
}

.c-card__btn {
  width: 60px;
  height: 60px;
  background-color: $blue-primary;
  border: none;
  outline: transparent;
  border-radius: 50%;
  position: relative;
  fill: $white;
  transform: rotate(90deg);

  @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {
    width: 30px;
    height: 30px;
  }
}

.c-card__btn-icon {
  width: 20px;
}
</style>
