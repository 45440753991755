<template>
  <section>
    <h1>{{ $t("general.other_games") }}</h1>
    <div class="l-other-games__slots-wrap">
      <div class="l-other-games__slots-swiper">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="slotGame in otherGames" :key="slotGame.name">
            <router-link
              :key="slotGame.name"
              :to="{ path: `/game/${slotGame.name}`, query: $route.query }"
              class="l-home__games-grid-item"
            >
              <app-card
                :img-path="slotGame.thumb"
                :title="slotGame.title"
                wrapper-classes="c-card--game"
                :isNewGame="slotGame.isNew"
              />
            </router-link>
          </swiper-slide>
        </swiper>
        <div class="l-other-games__slots-swiper-shadow"></div>
        <button
          slot="button-next"
          aria-label="slide-next"
          class="c-btn c-btn--padding-0 swiper-button-next c-swiper-btn c-swiper-btn--next"
        >
          <app-icon name="icon-triangle" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import AppCard from "../shared/app-card";
import AppIcon from "../shared/app-icon";
import { allGames } from "../../constants/index";

export default {
  name: "SlotsBlockchain",
  components: { AppCard, AppIcon },
  data() {
    return {
      allGames,
      swiperOption: {
        spaceBetween: 15,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
        breakpoints: {
          1024: {
            slidesPerView: 6,
          },
          767: {
            slidesPerView: 4,
          },
          240: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  computed: {
    gameName: function () {
      return this.$route.params.gameId.split("-").join("");
    },
    otherGames: function () {
      let games = { ...this.allGames };
      if (this.gameName in games) {
        delete games[this.gameName];
      }
      if (
        !(
          location.hostname.includes("localhost") ||
          location.hostname.includes("test.")
        )
      ) {
        for (let gameName of Object.keys(games)) {
          if (games[gameName].testing) {
            delete games[gameName];
          }
        }
      }
      return games;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-swiper-btn {
  background-color: transparent;
  border: none;
  outline: transparent;
  width: 36px !important;
  height: 36px !important;
  background-color: $blue-02;
  border-radius: 50%;
  flex-shrink: 0;

  &::after {
    display: none;
  }

  &.c-swiper-btn--prev {
    transform: rotate(-90deg);
  }

  &.c-swiper-btn--next {
  }

  > svg {
    color: $white;
    width: 13px;
    min-width: 13px;
    max-width: 13px;
    height: 13px;
    margin-left: 3px;
  }
}

.l-other-games__slots-wrap {
}

.l-other-games__slots-swiper {
  position: relative;
  overflow: hidden;
  margin-right: -10px;
  width: calc(100% - 50px);
}

.l-other-games__slots-swiper-shadow {
  background-image: linear-gradient(to left, #0b1729, rgba(11, 23, 41, 0));
  position: absolute;
  height: calc(100% - 16px);
  width: 120px;
  top: 0;
  right: 0;
  z-index: 2;
}

.swiper-container {
}
</style>
