<template>
  <section>
    <h2>{{ $t("general.all_games") }}</h2>
    <div class="l-home__games-grid">
      <router-link
        v-for="slotGame in games"
        :key="slotGame.name"
        :to="{ path: `/game/${slotGame.name}`, query: $route.query }"
        class="l-home__games-grid-item"
      >
        <app-card
          :img-path="slotGame.image"
          :title="slotGame.title"
          :description="$t('general.' + slotGame.description)"
          wrapper-classes="c-card--game"
          :isNewGame="slotGame.isNew"
        />
      </router-link>
    </div>
  </section>
</template>

<script>
import AppCard from "../shared/app-card";

import { allGames } from "../../constants/index";

export default {
  name: "AllGames",
  components: { AppCard },
  data() {
    return {
      allGames,
    };
  },
  computed: {
    games() {
      if (
        location.hostname.includes("localhost") ||
        location.hostname.includes("test.")
      ) {
        return this.allGames;
      }
      let myGames = { ...this.allGames };
      for (let gameName of Object.keys(myGames)) {
        if (myGames[gameName].testing) {
          delete myGames[gameName];
        }
      }
      return myGames;
    },
  },
};
</script>
<style lang="scss" scoped>
.l-home__games-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.l-home__games-grid-item {
  padding: 0 10px;
  flex: 1 1 50%;
  max-width: 50%;
  margin-bottom: 20px;

  @include media-min($screen-tablet-landscape) {
    flex: 1 1 25%;
    width: 25%;
    max-width: 25%;
  }
}
</style>
