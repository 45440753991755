import { UAL } from "universal-authenticator-library";
//import { Scatter } from 'ual-scatter';
import { Anchor } from 'ual-anchor';
import { TokenPocket } from '@tp-lab/ual-token-pocket';

import Backend from './backend.js';

import config from '../config.js'
const appName = 'luckyslot.bet';

function getPermission(user) {
    if (user.scatter) {
        return user.scatter.identity.accounts[0].authority;
    }
    if (user.requestPermission) {
        return user.requestPermission;
    }
    if (user.wallet.permissions) {
        return this.user.wallet.permissions;
    }
}

export default class Wallet {
    constructor(chain) {
        this.user = null;
        this.chainId = config[chain].chainId;
        this.setChain(chain);
    }

    getUsername() {
        if (!this.user) {
            return null;
        }
        return this.user.accountName ? this.user.accountName : this.user.wallet.name;
    }

    async setChain(chain) {
        if (chain == this.chain) {
            return;
        }
        this.chain = chain;
        this.chainId = config[this.chain].chainId;

        const endpoint = config[chain].apiUri;
        const indexOfFirstColon = endpoint.indexOf(':');
        const indexOfSecondColon = endpoint.slice(indexOfFirstColon + 1).indexOf(':') + indexOfFirstColon + 1;

        const chains = [{
            chainId: this.chainId,
            rpcEndpoints: [{
                protocol: endpoint.slice(0, indexOfFirstColon),
                host: endpoint.slice(indexOfFirstColon + 3, indexOfSecondColon),
                port: endpoint.slice(indexOfSecondColon + 1),
            }]
        }];

        if (this.user) {
            await this.logout();
        }

        this.ual = new UAL(chains, this.dappName, [
            //new Scatter(chains, { appName }),
            new Anchor(chains, { appName }),
            new TokenPocket(chains, { appName })
        ]);

        //this.ual.authenticators[0].walletName = 'scatter';
        this.ual.authenticators[0].walletName = 'anchor';
        this.ual.authenticators[1].walletName = 'tokenpocket';
    }

    async login(wallet) {
        if (this.user) {
            await this.logout();
        }

        this.wallet = wallet;
        this.authenticator = this.ual.authenticators.find(
            auth => auth.walletName.toLowerCase() == wallet.toLowerCase()
        );

        try {
            await this.authenticator.init();

            try {
                const users = await this.authenticator.login(null, 0, 0, 0);
                this.user = users[0];
                if (this.user) {
                    console.log(this.user);
                    if (this.user.scatter && this.user.scatter.identity.accounts[0].chainId != this.chainId) {
                        await this.logout();
                    } else {
                        localStorage.setItem('wallet', wallet);
                    }
                }
            } catch (error) {
                console.log(error);
                //this.loginMessage = `Error: ${error}`;
            }
        } catch (err) {
            console.log(err);
            /*let m = "Wallet unavailable";
            if (this.authenticator) {
                m = this.authenticator.getError() || err;
                m += `</br> <a target="_blank" href=${this.authenticator.getOnboardingLink()}>${this.authenticator.constructor.name
                    } Website</a>`;
            }
            this.loginMessage = m;*/
        }
    }

    async logout() {
        await this.authenticator.logout();
        this.user = null;
    }

    async getAuthToken() {
        const chainId = this.chainId;
        const accountName = this.getUsername(this.user);
        let auth = localStorage.getItem('auth');
        if (auth) {
            auth = JSON.parse(auth);
            if (auth[chainId] && auth[chainId][accountName]) {
                return auth[chainId][accountName];
            } else {
                return await this.getNewAuthToken();
            }
        } else {
            return await this.getNewAuthToken();
        }
    }

    async getNewAuthToken() {
        if (!this.user) {
            return;
        }
        const accountName = this.getUsername(this.user);
        let userSig;
        let loginData;
        if (this.wallet == 'anchor') {
            if (this.user.signerProof) {
                userSig = this.user.signerProof;
            } else {
                await this.logout();
                await this.login(this.wallet);
                return await this.getNewAuthToken();
            }
        } else {
            loginData = await Backend.login(this.chainId, accountName);
            let key;
            try {
                if (this.wallet == 'scatter') {
                    key = this.user.keys[0];
                } else if (this.wallet == 'anchor') {
                    key = this.user.signerKey;
                } else if (this.wallet == 'tokenpocket') {
                    key = this.user.wallet.address;
                }
                userSig = await this.user.signArbitrary(key, "Authenticate for off-chain bets " + accountName + ' ' + loginData.randomString);
            } catch (e) {
                console.log(e);
            }
            if (!userSig) {
                return await this.getNewAuthToken();
            }
        }
        let authData;
        if (this.wallet == 'anchor') {
            let signerRequest = this.user.signerRequest.signingData(this.chainId);
            authData = await Backend.getAuthTokenAnchor(this.chainId, accountName, userSig.toString(), signerRequest);
        } else {
            authData = await Backend.getAuthToken(this.chainId, accountName, userSig, loginData.randomString, loginData.serverSig);
        }

        if (authData.authToken) {
            let auth = localStorage.getItem('auth');
            if (auth) {
                auth = JSON.parse(auth);
            }
            if (!auth) {
                auth = {};
            }
            if (!(this.chainId in auth)) {
                auth[this.chainId] = {};
            }
            auth[this.chainId][accountName] = authData;
            localStorage.setItem('auth', JSON.stringify(auth));
            return authData;
        } else {
            return await this.getNewAuthToken();
        }
    }

    async claimReferral() {
        const actions = [{
            account: config[this.chain].contract,
            name: "claim",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                account: this.getUsername(this.user),
            },
        }];

        return await this.transaction(actions);
    }

    async withdraw(quantity) {
        const actions = [{
            account: config[this.chain].contract,
            name: "withdraweos",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                account: this.getUsername(this.user),
                quantity: quantity
            },
        }];

        return await this.transaction(actions);
    }

    async deposit(quantity, referrer) {
        const actions = [{
            account: 'eosio.token',
            name: "transfer",
            authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
            data: {
                from: this.getUsername(this.user),
                to: config[this.chain].contract,
                quantity: quantity,
                memo: 'wallet'
            },
        }];

        if (referrer) {
            actions.unshift({
                account: config[this.chain].contract,
                name: "setreferrer",
                authorization: [{ actor: this.getUsername(this.user), permission: getPermission(this.user) }],
                data: {
                    account: this.getUsername(this.user),
                    referrer
                },
            });
        }

        return await this.transaction(actions);
    }

    async transaction(actions) {
        try {
            let t = await this.user.signTransaction({ actions }, { broadcast: true });
            return t.transactionId;
        } catch (e) {
            alert(e);
        }
    }

}